// FlightPlan.jsx
import React from 'react';
import ItineraryItem from './ItineraryItem'; // Import the child component
import styles from './Itineary.module.css'; // Create and import your CSS module
import runningIcon from '../../assets/icons/running.svg'; // Import your airplane icon
import churchIcon from '../../assets/icons/church.svg'; // Import your airplane icon
import cocktailIcon from '../../assets/icons/cocktail.svg'; // Import your airplane icon
import dinnerIcon from '../../assets/icons/dinner.svg'; // Import your airplane icon
import coupleIcon from '../../assets/icons/couple.svg'; // Import your airplane icon
import bandIcon from '../../assets/icons/band.svg'; // Import your airplane icon
import dancingIcon from '../../assets/icons/dancing.svg'; // Import your airplane icon
import busIcon from '../../assets/icons/bus.svg'; // Import your airplane icon
import airplaneIcon from '../../assets/plane-icon.svg'; // Import your airplane icon

const FlightPlan = () => {
  const itinerary = [
    { icon: runningIcon, time: '08:00', event: 'Cape Run', location: 'Colchester Castle Park', extra: 'Sign up on the CapeRun tab' },
    { icon: churchIcon, time: '13:00', event: 'Ceremony', location: 'St James the Less Catholic Church' },
    { icon: airplaneIcon, time: '14:00', event: 'Shuttle to Layer Marney', location: 'St James the Less Catholic Church', extra: 'Make sure you have signed up to the bus on the ground transportation page' },
    { icon: cocktailIcon, time: '14:30', event: 'Cocktails, Canapes & Lawn Games', location: 'Layer Marney Tower' },
    { icon: dinnerIcon, time: '17:00', event: 'Wedding Breakfast, Speeches & Cake Cutting', location: 'Layer Marney Tower', extra: 'Choose your meal options when you Check-in' },
    { icon: coupleIcon, time: '20:00', event: 'Entrance Dance & Games', location: 'Layer Marney Tower' },
    { icon: bandIcon, time: '20:30', event: 'Ceilidh Band', location: 'Layer Marney Tower' },
    { icon: dancingIcon, time: '22:30', event: 'DJ takes over', location: 'Layer Marney Tower' },
    { icon: airplaneIcon, time: '23:00', event: 'First Shuttle Departs', location: 'Layer Marney Tower', extra: 'Make sure you have signed up to the bus on the ground transportation tab'  },
    { icon: airplaneIcon, time: '00:15', event: 'Last Shuttle Departs', location: 'Layer Marney Tower', extra: 'Make sure you have signed up to the bus on the ground transportation tab'  },
  ];

  return (
    
    <div className={styles.container}>
    
      <h1>Flight Plan</h1>
      <div className={styles.timelineLine}></div> {/* This is the continuous line */}
      {itinerary.map((item, index) => (
        <ItineraryItem key={index} icon={item.icon} time={item.time} event={item.event} location={item.location} extra={item.extra} />
      ))}

    
    </div>
  );
};

export default FlightPlan;










