import { useState } from 'react'
import React from 'react'
import { useLogin } from '../../hooks/useLogin'
import Aeroplane from '../../assets/aeroplane.jpg'

//styles
import styles from './Home.module.css'

export default function Home() {

    return (
        <div className={styles.container}>

            <div className={styles.imageContainer}>
                <img src={Aeroplane} alt="Aeroplane" />
            <div className={styles.overlayText}>
                <h3>Time to start packing...</h3>
                <p>Warm up your winter with these amazing deals!</p>
            </div>
            </div>
                <h3 className={styles.box4}>Latest Flight Deals</h3>


            <a href="/" className={styles.box3}><p>Le Touqet (France)</p><p>£500</p></a>
            <a href="/" className={styles.box3} ><p>Dubai</p><p>£2,500</p></a>
            <a href="/" className={styles.box3} ><p>Muscat</p><p>£3,500</p></a>
            <a href="/" className={styles.box3} ><p>Riga</p><p>£799</p></a>
            <a href="/" className={styles.box3} ><p>Tbilisi</p><p>£5,000</p></a>
            <a href="/" className={styles.box3} ><p>Tenerife</p><p>£300</p></a>
            <a href="/" className={styles.box3} ><p>Amsterdam</p><p>£200</p></a>
            <a href="/" className={styles.box3} ><p>Washington, D.C.</p><p>£1,500</p></a>
        </div>
    )
}