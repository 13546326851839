// src/components/UserData.js
import React, { useEffect, useState } from "react";
import { projectFirestore } from "../../firebase/config";
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useDebounce } from '../../hooks/useDebounce';
import styles from "./CaperunResults.module.css";

const RunnerData = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(collection(projectFirestore, "guests"), where("caperun", "==", "runner"));
        const snapshot = await getDocs(q);
        let runnersData = snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(runner => runner.time);

        runnersData.sort((a, b) => parseTime(a.time) - parseTime(b.time));

        runnersData = runnersData.map((runner, index) => ({
          ...runner,
          position: calculatePosition(index)
        }));
        setData(runnersData);
        setFilteredData(runnersData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filtered = data.filter(runner =>
      `${runner.firstname} ${runner.lastname}`.toLowerCase().includes(debouncedSearch.toLowerCase())
    );
    setFilteredData(filtered);
  }, [debouncedSearch, data]);

  const calculatePosition = (index) => {
    return index + 1;
  };

  const parseTime = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 60 * 60 + minutes * 60 + seconds;
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className={styles.container}>

      {filteredData.length === 0 ? (
        <p>No results yet; check back later!</p>
      ) : (
        <div>
          <input
            type="text"
            placeholder="Search by name"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className={styles.searchBox}
          />
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Position</th>
                <th>Name</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map(guest => (
                <tr key={guest.id} className={styles.userRow}>
                  <td>{guest.position}</td>
                  <td>{guest.firstname} {guest.lastname}</td>
                  <td>{guest.time}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default RunnerData;
