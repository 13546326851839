// ItineraryItem.jsx
import React from 'react';
import styles from './ItineraryItem.module.css';
import airplaneIcon from '../../assets/plane-icon.svg'; // Import your airplane icon

const ItineraryItem = ({icon, time, event, location, extra }) => {
  return (
    <div className={styles.itineraryItem}>
      <div className={styles.iconContainer}>
        <div className={styles.timelineCircle}>
          <img src={icon} alt={icon} className={styles.icon} />
        </div>
      </div>

      <div className={styles.timeEvent}>
        <div className={styles.time}>{time}</div>
        <div className={styles.event}>{event}</div>
        <div className={styles.location}>{location}</div>
        <div className={styles.extra}>{extra}</div>
      </div>
    </div>
  );
};

export default ItineraryItem;
