import React, { useState } from 'react';
import Tabs from '../../components/Tabs';
import StarterPrawn from '../../assets/Meals/Starter_Prawns.JPEG';
import StarterChicken from '../../assets/Meals/Starter_Chicken.JPEG';
import MainDuck from '../../assets/Meals/Main_Duck.JPEG';
import MainLamb from '../../assets/Meals/Main_Lamb.JPEG';
import MainVeggie from '../../assets/Meals/Main_Veggie.JPEG';
import styles from './Meals.module.css'; // Import the CSS module

function Meals() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const tabs = ['Starters', 'Mains', 'Dessert'];

  const startersData = [
    {
      title: 'Pan Fried Citrus and Pepper Prawns',
      image: StarterPrawn,
      shortDescription: 'Baby Leaves, Aioli and Crispy Music Paper',
    },
    {
      title: 'Sesame Chicken',
      image: StarterChicken,
      shortDescription: 'Spring Onion, Chilli and Lime Dressing',
    },
    {
      title: "Glazed Goat's Cheese (v)",
      image: StarterChicken,
      shortDescription: 'Tomato Chutney and Toasted Hazelnuts',
    },
  ];

  const mainsData = [
    {
      title: 'Slow Roasted Shoulder of Lamb',
      image: MainDuck,
      shortDescription: 'Rosemary Jus, Gratin Dauphinoise',
    },
    {
      title: 'Thyme Roasted Breast of Chicken, Crispy Pancetta',
      image: MainLamb,
      shortDescription: 'Gratin Dauphinoise, Green Peppercorn Sauce',
    },
    {
      title: 'Mushroom Kiev (v)',
      image: MainVeggie,
      shortDescription: 'Parsley Sauce',
    },
  ];

  const dessertData = [
    {
      title: 'Desserts',
      image: '/images/dessert/dessert1.jpg', // Adjust the image path
      shortDescription: 'A trio of desserts ',
    },
  ];


  const content = [
    <div key="starters" className={styles.itemsContainer}>
      {startersData.map((item, index) => (
        <div key={index} className={styles.item}>
          <h3>{item.title}</h3>
          {/* <img src={item.image} alt={item.title} className={styles.centeredImage} /> */}
          <p>{item.shortDescription}</p>
        </div>
      ))}
    </div>,
    <div key="mains" className={styles.itemsContainer}>
      {mainsData.map((item, index) => (
        <div key={index} className={styles.item}>
          <h3>{item.title}</h3>
          {/* <img src={item.image} alt={item.title} className={styles.centeredImage} /> */}
          <p>{item.shortDescription}</p>
        </div>
      ))}
    </div>,
    <div key="dessert" className={styles.itemsContainer}>
      {dessertData.map((item, index) => (
        <div key={index} className={styles.item}>
          <h3>{item.title}</h3>
          {/* <img src={item.image} alt={item.title} className={styles.centeredImage} /> */}
          <p>{item.shortDescription}</p>
        </div>
      ))}
    </div>,
  ];

  return (
    <div className="App">
      <Tabs tabs={tabs} onChangeTab={handleTabChange} />
      {content[activeTab]}
    </div>
  );
}

export default Meals;