import styles from './Footer.module.css'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'


export default function Footer() {
  const location = useLocation()
  const [currentPath, setCurrentPath] = useState('')

  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [location.pathname])

  return (
    <div>
      <div className={styles.footer}>
        <ul>

          <li className={currentPath === '/' ? styles.activeNav : ''}><Link to="/">Home</Link></li>

        </ul>
      </div>
      <div className={styles.footer}>
        <p>© Copyright 2024. All Rights Reserved.</p>
      </div>
    </div>
  )
}
