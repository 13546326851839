import React from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'

// styles
import styles from './Manage.module.css'

// components
// import TransactionForm from './TransactionForm'
// import TransactionList from './TransactionList'
// import GuestForm from './GuestForm'
import GuestList from './GuestList'
import Meals from './Meals'

export default function Home() {
  const { user } = useAuthContext()
  const { documents, error } = useCollection(
    'guests', ["uid", "==", user.uid], ['createdAt', 'desc']
    )

  return (
    <div className={styles.container}>
       <div className={styles.sidebar}>
        <Meals/>
      </div>
      <div className={styles.content}>
        {error && <p>{error}</p>}
        {documents && <GuestList guests={documents} />}
       </div>
    </div>
  )
}
