import React, { useState, useEffect } from 'react';
import styles from './CountdownTimer.module.css'

const CountdownTimer = ({ endDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(endDate) - +new Date();
    return difference > 0 ? difference / 1000 : 0;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    // Update the timer every second
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Cleanup the interval
    return () => clearInterval(interval);
  }, [endDate]);

  // Convert time to days, hours, minutes, and seconds
  const days = Math.floor(timeLeft / (3600 * 24));
  const hours = Math.floor((timeLeft % (3600 * 24)) / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = Math.floor(timeLeft % 60);

  return (
    <div>
      <div className={styles.countdownContainer}>
        <div className={styles.countdownTitle}>Countdown to flight:</div>
        <div className={styles.countdown}>

          <div className={styles.timeSegment}>{days}<span className={styles.timeLabel}></span> Days</div>
          <div className={styles.timeSegment}>{hours}<span className={styles.timeLabel}></span> Hours</div>
          <div className={styles.timeSegment}>{minutes}<span className={styles.timeLabel}></span> Minutes</div>
          <div className={styles.timeSegment}>{seconds}<span className={styles.timeLabel}></span> Seconds</div>
        </div>
      </div>


    </div>
  );
};

export default CountdownTimer;
