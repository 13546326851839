import React, { useState } from 'react';
import styles from './Tabs.module.css';

const Tabs = ({ tabs, onChangeTab }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
    onChangeTab(index);
  };

  return (
    <div className={styles.tabs}>
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`${styles.tab} ${index === activeTab ? styles.activeTab : ''}`}
          onClick={() => handleTabClick(index)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
