// src/hooks/useFirestore.js

import { useReducer, useEffect, useState } from 'react';
import { projectFirestore } from '../firebase/config';
import { collection, addDoc, doc, deleteDoc, updateDoc, getDocs } from 'firebase/firestore';

const initialState = {
    document: null,
    isPending: false,
    error: null,
    success: null
};

const firestoreReducer = (state, action) => {
    switch (action.type) {
        case 'IS_PENDING':
            return { isPending: true, document: null, success: false, error: null };
        case 'ADDED_DOCUMENT':
            return { isPending: false, document: action.payload, success: true, error: null };
        case 'DELETED_DOCUMENT':
            return { isPending: false, document: null, success: true, error: null };
        case 'UPDATED_DOCUMENT':
            return { isPending: false, document: action.payload, success: true, error: null };
        case 'ERROR':
            return { isPending: false, document: null, success: false, error: action.payload };
        default:
            return state;
    }
};

export const useFirestore = (collectionName) => {
    const [response, dispatch] = useReducer(firestoreReducer, initialState);
    const [isCancelled, setIsCancelled] = useState(false);

    // collection ref
    const ref = collection(projectFirestore, collectionName);

    // only dispatch if not cancelled
    const dispatchIfNotCancelled = (action) => {
        if (!isCancelled) {
            dispatch(action);
        }
    };

    // add document
    const addDocument = async (docData) => {
        dispatch({ type: 'IS_PENDING' });

        try {
            const addedDocument = await addDoc(ref, docData);
            dispatchIfNotCancelled({ type: 'ADDED_DOCUMENT', payload: addedDocument });
        } catch (err) {
            dispatchIfNotCancelled({ type: 'ERROR', payload: err.message });
        }
    };

    // delete document
    const deleteDocument = async (id) => {
        dispatch({ type: 'IS_PENDING' });

        try {
            await deleteDoc(doc(ref, id));
            dispatchIfNotCancelled({ type: 'DELETED_DOCUMENT' });
        } catch (err) {
            dispatchIfNotCancelled({ type: 'ERROR', payload: err.message });
        }
    };

    // update document
    const updateDocument = async (id, newData) => {
        dispatch({ type: 'IS_PENDING' });

        try {
            const updatedDocument = await updateDoc(doc(ref, id), newData);
            dispatchIfNotCancelled({ type: 'UPDATED_DOCUMENT', payload: updatedDocument });
        } catch (err) {
            dispatchIfNotCancelled({ type: 'ERROR', payload: err.message });
        }
    };

    // fetch all documents
    const fetchAllDocuments = async () => {
        dispatch({ type: 'IS_PENDING' });

        try {
            const querySnapshot = await getDocs(ref);
            let documents = [];
            querySnapshot.forEach(doc => {
                documents.push({ ...doc.data(), id: doc.id });
            });
            dispatchIfNotCancelled({ type: 'ADDED_DOCUMENT', payload: documents });
        } catch (err) {
            dispatchIfNotCancelled({ type: 'ERROR', payload: err.message });
        }
    };

    useEffect(() => {
        return () => setIsCancelled(true);
    }, []);

    return { addDocument, deleteDocument, updateDocument, fetchAllDocuments, response };
};
