// src/components/UserData.js
import React, { useEffect, useState } from "react";
import { projectFirestore } from "../../firebase/config";
import { collection, query, where, getDocs } from 'firebase/firestore';
import styles from "./CaperunResults.module.css";

const VolunteerData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(collection(projectFirestore, "guests"), where("caperun", "==", "volunteer"));
        const snapshot = await getDocs(q);
        let volunteersData = snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          

        

        volunteersData = volunteersData.map((volunteer, index) => ({
          ...volunteer,
          
        }));
        setData(volunteersData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className={styles.container}>
    {data.length === 0 ? (
        <p>We'll thank the volunteers here later!</p>
      ) : (
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {data.map(guest => (
            <tr key={guest.id} className={styles.userRow}>
              <td>{guest.firstname} {guest.lastname}</td>
            </tr>
          ))}
        </tbody>
      </table>
      )}
    </div>
  );
};

export default VolunteerData;
