import React, { useState } from 'react';
import SeatMap from './Seatmap'; // Import SeatMap component
import styles from './Bus.module.css';
import BusGuestList from './BusGuestList';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useCollection } from '../../hooks/useCollection';
import flyingNug from '../../assets/nuggyflying.JPEG';

export default function Bus() {
  const { user } = useAuthContext();
  const { documents, error } = useCollection(
    'guests',
    ['uid', '==', user.uid],
    ['createdAt', 'desc']
  );
  const initialGuestState = {};

  const { allGuests } = useCollection(
    'guests',
  )


  // Maintain a state to track whether a guest is selected
  const [selectedGuest, setSelectedGuest] = useState(initialGuestState);

  // Function to set the selected guest
  const handleGuestSelection = (guest) => {
    setSelectedGuest(guest);
  };

  const updateSelectedGuestSeat = (newSeat) => {
    setSelectedGuest((prevGuest) => ({ ...prevGuest, seat: newSeat }));
  };

  return (
    <div >
      <div className={styles.container}>
        <h1>Ground Transportation</h1>
        <p>The ceremony will be held at <span className={styles.dateHeading}>St James the Less Catholic Church</span>, centrally located in Colchester, just a short stroll from the recommended hotels.</p>
        <p>Post-ceremony, a dedicated bus will be provided to transport guests to the reception venue at <a href="https://maps.app.goo.gl/2nkGcSuD6emGXhDN8" target="_blank" rel="noopener noreferrer">Layer Marney Towers</a>, a convenient 20-minute drive (12.8 km) away.</p>
        <p>At the end of the night the bus will return you to Colchester Town Centre, dropping you off at the recommended hotels (The George & St Nicholas). Scheduled return buses are at <span className={styles.dateHeading}>23:00</span> and <span className={styles.dateHeading}>00:15</span>. If you need an earlier departure, it will of course be possible to call a taxi.</p>
        <div className={styles.mainImg}>
          <img src={flyingNug} alt="Cara and Capewell Flying" />
        </div>
        <p>Please select whether you require the bus to Layer Marney after the ceremony and whether you require a bus and your preffered time back to Colchester below.</p>
        {error && <p>{error}</p>}
        {documents && (
          <BusGuestList guests={documents} onGuestSelection={handleGuestSelection} />
        )}
        <iframe
          title="journeymap"
          src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d78855.97614005132!2d0.7773787889047873!3d51.8564052943388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x47d904ff030fa4a1%3A0x617cae1b447576e0!2sSt%20James%20the%20Less%20%26%20St%20Helen%20Church%2C%2051%20Priory%20St%2C%20Colchester%20CO1%202QB!3m2!1d51.8893632!2d0.9084987999999999!4m5!1s0x47d91d16a33f1d9b%3A0xd075cbfdc783b725!2sLayer%20Marney%20Tower%20Weddings%2C%20Tower!3m2!1d51.822562299999994!2d0.7967998!5e0!3m2!1sen!2suk!4v1706371286076!5m2!1sen!2suk"
          width="100%"
          height="480"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          frameBorder="0">
        </iframe>
      </div>

      {/* Pass the 'documents' array as 'allGuests' prop */}
      {/* {selectedGuest && <SeatMap selectedGuest={selectedGuest} allGuests={documents} onUpdateSeat={updateSelectedGuestSeat} />} */}

    </div>
  );
}
