// ./src/firebase/config.js

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, Timestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAf5Wd44blPykc6td4NMedxI1dst3dFZE4",
  authDomain: "my-money-934c8.firebaseapp.com",
  projectId: "my-money-934c8",
  storageBucket: "my-money-934c8.appspot.com",
  messagingSenderId: "93837421720",
  appId: "1:93837421720:web:07eff267776356810cf4ed"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize services
const projectFirestore = getFirestore(firebaseApp);
const projectAuth = getAuth(firebaseApp);

// Timestamp
const timestamp = Timestamp;

// Export services
export { projectFirestore, projectAuth, timestamp };
export default firebaseApp;
