import RunnerData from './RunnerData.js'
import WalkerData from './WalkerData.js'
import VolunteerData from './VolunteerData.js'

const CaperunResults = () => {
  return (
    <div className="App">
    A big thank-you to our Volunteers!
      <VolunteerData />
      Run results
      <RunnerData />
      Walker Results
      <WalkerData />
    </div>
  )
}

export default CaperunResults
