import { useEffect, useState } from "react";
import { useFirestore } from "../../hooks/useFirestore";
import { useCollection } from '../../hooks/useCollection';
import { timestamp } from '../../firebase/config'

export default function GuestForm({ uid }) {
    const [selectedUid, setSelectedUid] = useState('');
    const { documents: reservations, error: reservationsError } = useCollection('reservations', null, null);
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const { addDocument, response } = useFirestore('guests')
    const rsvp = ''
    const createdAt = timestamp.fromDate(new Date())
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [isBookingRefValid, setIsBookingRefValid] = useState(true);



    const handleUserChange = (e) => {
        setSelectedUid(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!selectedUid) {
            // Invalid booking reference, show error
            setIsBookingRefValid(false);
            return; // Prevent form submission
        }
        setIsBookingRefValid(true);
        if (selectedUid) {
            addDocument({
                uid: selectedUid,
                firstname,
                lastname,
                rsvp,
                createdAt
            });
        }
    }

    useEffect(() => {
        if (response.success) {
            // Resetting form fields to initial state
            // setSelectedUid('');
            setFirstname('');
            setLastname('');
            // Add other fields if necessary, like resetting rsvp
            // Show success message
            setShowSuccessMessage(true);

            // Hide success message after 10 seconds
            const timer = setTimeout(() => {
                setShowSuccessMessage(false);
            }, 10000);

            // Clear the timeout if the component unmounts
            return () => clearTimeout(timer);
        }
    }, [response.success]);


    return (
        <>
            <h3>Add a Guest</h3>
            <form onSubmit={handleSubmit}>

                <label htmlFor="user-select">Select User:</label>
                <select
                    id="user-select"
                    onChange={handleUserChange}
                    className={isBookingRefValid ? "" : "error"}
                >
                    <option value="">Select a Booking</option>
                    {reservations && reservations
                        .sort((a, b) => a.lastname.localeCompare(b.lastname))
                        .map(reservation => (
                            <option key={reservation.uid} value={reservation.uid}>
                                {reservation.lastname} - {reservation.bookingref}
                            </option>
                        ))}
                </select>

                <label>
                    <span>First Name:</span>
                    <input
                        type="text"
                        required
                        onChange={(e) => setFirstname(e.target.value)}
                        value={firstname}
                    />
                </label>
                <label>
                    <span>Last Name:</span>
                    <input
                        type="text"
                        required
                        onChange={(e) => setLastname(e.target.value)}
                        value={lastname}
                    />
                </label>
                <button>Add Guest</button>
            </form>
            {showSuccessMessage && <div className="success-message">Guest added successfully!</div>}
            {!isBookingRefValid && <div className="error-message">Please select a booking reference.</div>}
        </>
    )
}