import { useState } from 'react'
import React from 'react'
import { useLogin } from '../../hooks/useLogin'
// import { BrowserRouter } from 'react-router-dom'

//styles
import styles from '../../main.css'

export default function Home() {


    return (
        <div>
            <h1>Book</h1>
        </div>
    )
}