import './index.css'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext';

// pages & components
import Home from './pages/home/Home';
import Signup from './pages/signup/Signup';
import Login from './pages/login/Login';
import Book from './pages/book/Book';
import Manage from './pages/manage/Manage';
import Accommodation from './pages/accommodation/Accommodation';
import Food from './pages/food/Food';
import Bus from './pages/bus/Bus';
import Flightplan from './pages/itineary/Flightplan';
import Caperun from './pages/caperun/Caperun';
import Faq from './pages/faq/Faq';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Addguest from './pages/addguest/Addguest';
import AccordionMenu from './components/AccordionMenu';
import Layout from './components/HOC/Layout';
import Footer from './components/Footer';
import FlightPlan from './pages/itineary/Flightplan';
import Destination from './pages/Destination/Destination'
import Admin from './pages/admin/Admin.js'
import Upload from './pages/caperun/CapeRunUpload.js'



function App() {
  const { authIsReady, user } = useAuthContext()

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>

          <Navbar />
        <Layout>
          {/* <AccordionMenu></AccordionMenu> */}
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/book' element={<Book />}></Route>
            
            {/* These are logged out links */}
            <Route path='/login' element={user ? <Navigate to="/Destination"/> : <Login />}></Route>

            {/* These are logged in links */}
            <Route path='/Manage' element={!user ? <Navigate to="/"/> : <Manage />}></Route>

            <Route path='/Accommodation' element={!user ? <Navigate to="/"/> : <Accommodation />}></Route>
            <Route path='/FoodOnBoard' element={!user ? <Navigate to="/"/> : <Food />}></Route>
            <Route path='/GroundTransportation' element={!user ? <Navigate to="/"/> : <Bus />}></Route>
            <Route path='/Flightplan' element={!user ? <Navigate to="/"/> : <Flightplan />}></Route>
            <Route path='/CapeRun' element={!user ? <Navigate to="/"/> : <Caperun />}></Route>
            <Route path='/Faq' element={!user ? <Navigate to="/"/> : <Faq />}></Route>
            <Route path='/' element={!user ? <Navigate to="/"/> : <Home />}></Route>
            <Route path='/Destination' element={!user ? <Navigate to="/"/> : <Destination />}></Route>

            {/* These are secret links */}
            <Route path='/signup' element={user ? <Navigate to="/"/> : <Signup />}></Route>
            <Route path='/addguest' element={!user ? <Navigate to="/"/> : <Addguest />}></Route>
            <Route path='/Admin' element={!user ? <Navigate to="/"/> : <Admin />}></Route>
            <Route path='/Upload' element={!user ? <Navigate to="/"/> : <Upload />}></Route>
          </Routes>
        </Layout>
        <Footer />
        </BrowserRouter>
      )}
    </div>
  );
}

export default App
