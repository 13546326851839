import { useEffect, useState } from 'react'
import { useSignup } from '../../hooks/useSignup'
import Papa from 'papaparse';
//import { useFirestore } from "../../hooks/useFirestore";

// styles
import styles from './Signup.module.css'

export default function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [displayName, setDisplayName] = useState('')
  const { signup, isPending, error, success } = useSignup()
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleBookingRefChange = (e) => {
    // Convert the input value to uppercase
    const uppercaseRef = e.target.value.toUpperCase();
    setPassword(uppercaseRef);
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const sanitizedSurname = email.replace(/\s/g, '');
    signup(sanitizedSurname + '@' + password + '.com', password, displayName)
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const text = event.target.result;
      parseCSV(text);
    };

    reader.readAsText(file);
  };

  const parseCSV = (data) => {
    Papa.parse(data, {
      header: true,
      complete: (results) => {
        processCSVData(results.data);
      }
    });
  };

  const processCSVData = (data) => {
    data.forEach(row => {
      // Assuming the columns are named appropriately in your CSV
      const email = row.GuestSurnameName;
      const sanitizedSurname = email.replace(/\s/g, '');
      const password = row.BookingReference;
      const displayName = `${row.FirstName} ${row.LastName}`;

      // Use your signup function
      signup(sanitizedSurname + '@' + password + '.com', password, displayName);

      // Add logic to attach guest information to the account
    });
  };

  useEffect(() => {
    if (success) {
      setShowSuccessMessage(true);

      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [success]);


  return (
    <div>

      <form onSubmit={handleSubmit} className={styles['signup-form']}>
        <h2>Create new booking</h2>
        <label>
          <span>Surname:</span>
          <input
            type="text"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </label>
        <label>
          <span>Booking Ref:</span>
          <input
            type="text"
            onChange={handleBookingRefChange}
            value={password}
          />
        </label>

        {!isPending && <button className="btn">Sign Up</button>}
        {isPending && <button className="btn" disabled>loading</button>}
        {error && <p>{error}</p>}

      </form>
      {showSuccessMessage && <div className="success-message">Booking added successfully! For long boring complicated reasons, you need to log back in as us in order to add the guests.</div>}
      {/* <input type="file" accept=".csv" onChange={handleFileUpload} /> */}

    </div>
  )
}