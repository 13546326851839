// AccordionMenu.js
import React, { useState, useEffect } from 'react';
import styles from './AccordionMenu.module.css';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as MenuIcon } from '../assets/icons/menu.svg';


const AccordionMenu = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false); // Close the menu
  };

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 920);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial check for mobile screen
    handleResize();

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.menuContainer}>
      {isMobile && (
        <div className={styles.container}>
          <button className={styles.menuButton} onClick={toggleMenu}>
            Menu <MenuIcon className={styles.icon} />
          </button>
        </div>

      )}
      <div className={`${styles.menu} ${isOpen ? styles.open : ''}`}>
        <ul>
          {!user && (
            <>
              <li className={currentPath === '/login' ? styles.activeNav : ''}><Link to="/login" onClick={closeMenu}>Manage My Booking</Link></li>
              {/* <li><Link to="#">Information</Link></li>
              <li><Link to="#">Book</Link></li> */}
            </>
          )}

          {user && (
            <>
              <li className={currentPath === '/Destination' ? styles.activeNav : ''}><Link to="/Destination" onClick={closeMenu}>Destination</Link></li>
              <li className={currentPath === '/Flightplan' ? styles.activeNav : ''}><Link to="/Flightplan" onClick={closeMenu}>Flight Plan</Link></li>
              <li className={currentPath === '/Manage' ? styles.activeNav : ''}><Link to="/Manage" onClick={closeMenu}>Check-in</Link></li>
              <li className={currentPath === '/Accommodation' ? styles.activeNav : ''}><Link to="/Accommodation" onClick={closeMenu}>Accommodation</Link></li>
              <li className={currentPath === '/GroundTransportation' ? styles.activeNav : ''}><Link to="/GroundTransportation" onClick={closeMenu}>Ground Transportation</Link></li>
              <li className={currentPath === '/CapeRun' ? styles.activeNav : ''}><Link to="/CapeRun" onClick={closeMenu}>CapeRun</Link></li>
              <li className={currentPath === '/Faq' ? styles.activeNav : ''}><Link to="/Faq" onClick={closeMenu}>FAQs</Link></li>
              <li className={currentPath === '/' ? styles.activeNav : ''}><Link onClick={() => { logout(); closeMenu() }}>Logout</Link></li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AccordionMenu;
