// Layout.js
import React from 'react';
import AccordionMenu from '../AccordionMenu';

const Layout = ({ children }) => {
  return (
    <div className="page-container">
      <AccordionMenu />
      <div className="content-container">{children}</div>
    </div>
  );
};

export default Layout;
