import React, { useState, useEffect } from 'react';
import { useFirestore } from '../../hooks/useFirestore';
import styles from './BusGuestList.module.css';

export default function BusGuestList({ guests, onGuestSelection }) {
  const { deleteDocument, response, updateDocument } = useFirestore('guests');
  const [selectedGuestId, setSelectedGuestId] = useState(null);
  
  const initialSelectedBus2Lm = guests.reduce((acc, guest) => {
    acc[guest.id] = guest.bus2lm || ''; // Use the Caperun value from the guest or set it to an empty string if undefined
    return acc;
  }, {});
  
  const initialSelectedBus2Col = guests.reduce((acc, guest) => {
    acc[guest.id] = guest.bus2col || ''; // Use the Caperun value from the guest or set it to an empty string if undefined
    return acc;
  }, {});
  
  const [selectedBus2Lm, setSelectedBus2Lm] = useState(initialSelectedBus2Lm);
  const [selectedBus2Col, setSelectedBus2Col] = useState(initialSelectedBus2Col);  
  
  useEffect(() => {
    // Select the first guest when the component mounts
    if (guests.length > 0 && selectedGuestId === null) {
      setSelectedGuestId(guests[0].id);
      onGuestSelection(guests[0]);
    }
  }, [guests, onGuestSelection, selectedGuestId]);

  const handleGuestClick = (guestId, guest) => {
    setSelectedGuestId(guestId);
    onGuestSelection(guest);
  };

  const handleDropdownChange = (guestId, field, eventOrOption) => {
    let newBus2Lm;
    let newBus2Col;
    
    if (field === 'bus2lm') {
      newBus2Lm = eventOrOption.target.value;
      setSelectedBus2Lm(prevSelectedBus2Lm => ({ ...prevSelectedBus2Lm, [guestId]: newBus2Lm }));
      // const newCaperun = selectedCaperun[guestId] || guestId.caperun || '';
      updateDocument(guestId, {bus2lm:newBus2Lm})
    } else if (field === 'bus2col') { 
      newBus2Col = eventOrOption.target.value;
      setSelectedBus2Col(prevSelectedBus2Col => ({ ...prevSelectedBus2Col, [guestId]: newBus2Col }));
      // const newCaperun = selectedCaperun[guestId] || guestId.caperun || '';
      updateDocument(guestId, {bus2col:newBus2Col})
    }

  };

  return (
    <div>
      <ul className={styles.transactions}>
        {guests
          .filter(guest => guest.rsvp === 'true') // Filter guests with RSVP set to 'yes'
          .map((guest) => (
            <li
              key={guest.id}
              className={selectedGuestId === guest.id ? styles.selected : ''}
              onClick={() => handleGuestClick(guest.id, guest)} // Call the onGuestSelection callback
            >
            <div className={styles.namesRow}>
              <p className={styles.lastname}>{guest.firstname}&nbsp;</p>
              <p className={styles.name}>{guest.lastname}</p>
            </div>

              <div className={styles.optionsRow}>
              <p>Bus to Layer Marney</p>
                <input
                  type="radio"
                  id={`bus2lm_yes_${guest.id}`}
                  name={`bus2lm_${guest.id}`}
                  value="yes"
                  className={styles.hiddenRadio}
                  checked={selectedBus2Lm[guest.id] === 'yes'}
                  onChange={(e) => handleDropdownChange(guest.id, 'bus2lm', e)}
                />
                <label htmlFor={`bus2lm_yes_${guest.id}`} className={styles.selectableBox}>
                  Yes
                </label>

                <input
                  type="radio"
                  id={`bus2lm_no_${guest.id}`}
                  name={`bus2lm_${guest.id}`}
                  value="no"
                  className={styles.hiddenRadio}
                  checked={selectedBus2Lm[guest.id] === 'no'}
                  onChange={(e) => handleDropdownChange(guest.id, 'bus2lm', e)}
                />
                <label htmlFor={`bus2lm_no_${guest.id}`} className={styles.selectableBox}>
                  No
                </label>
                </div>

                <div className={styles.optionsRow}>
              <p>Bus to Colchester</p>
                <input
                  type="radio"
                  id={`bus2col_2300_${guest.id}`}
                  name={`bus2col_${guest.id}`}
                  value="2300"
                  className={styles.hiddenRadio}
                  checked={selectedBus2Col[guest.id] === '2300'}
                  onChange={(e) => handleDropdownChange(guest.id, 'bus2col', e)}
                />
                <label htmlFor={`bus2col_2300_${guest.id}`} className={styles.selectableBox}>
                  23:00
                </label>

                <input
                  type="radio"
                  id={`bus2col_0015_${guest.id}`}
                  name={`bus2col_${guest.id}`}
                  value="0015"
                  className={styles.hiddenRadio}
                  checked={selectedBus2Col[guest.id] === '0015'}
                  onChange={(e) => handleDropdownChange(guest.id, 'bus2col', e)}
                />
                <label htmlFor={`bus2col_0015_${guest.id}`} className={styles.selectableBox}>
                  00:15
                </label>

                <input
                  type="radio"
                  id={`bus2col_no_${guest.id}`}
                  name={`bus2col_${guest.id}`}
                  value="no"
                  className={styles.hiddenRadio}
                  checked={selectedBus2Col[guest.id] === 'no'}
                  onChange={(e) => handleDropdownChange(guest.id, 'bus2col', e)}
                />
                <label htmlFor={`bus2col_no_${guest.id}`} className={styles.selectableBox}>
                  No
                </label>
                </div>

            </li>
          ))}
      </ul>
      <p className={styles.info}>Any guests not listed have their RSVP set to no</p>
    </div>
  );
}
