import { useEffect, useState, React } from 'react'
import GuestForm from './GuestForm'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'
import styles from './Addguest.module.css'
import Papa from 'papaparse';
import { useFirestore } from "../../hooks/useFirestore";
import { timestamp } from '../../firebase/config'


const Addguest = () => {
  const { user } = useAuthContext()

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [uid, setUid] = useState('')
  const [bookingref, setBookingref] = useState('')
  const { addDocument, response } = useFirestore('reservations')
  const rsvp = ''
  const createdAt = timestamp.fromDate(new Date())


  const { documents, error } = useCollection(
    'reservations', ["uid", "==", user.uid], ['createdAt', 'desc']
  )

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const text = event.target.result;
      parseCSV(text);
    };

    reader.readAsText(file);
  };

  const parseCSV = (data) => {
    Papa.parse(data, {
      header: true,
      complete: (results) => {
        processCSVData(results.data);
      }
    });
  };

  const processCSVData = (data) => {
    data.forEach(row => {
      const lastname = row.surname;
      const bookingref = row.bookingref;
      const uid = row.uid;

      addDocument({
        uid,
        lastname,
        bookingref,
        createdAt
      })

    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.transactions}>
        {/* <input type="file" accept=".csv" onChange={handleFileUpload} /> */}
      </div>
      <div className={styles.sidebar}>
        <GuestForm uid={user.uid} />
      </div>
    </div>
  )
}

export default Addguest
