// src/hooks/useCollection.js

import { useEffect, useState, useRef } from "react";
import { collection, query as firestoreQuery, where, orderBy as firestoreOrderBy, onSnapshot } from "firebase/firestore";
import { projectFirestore } from "../firebase/config.js";

export const useCollection = (collectionName, _query, _orderBy) => {
  const [documents, setDocuments] = useState(null);
  const [error, setError] = useState(null);

  const query = useRef(_query).current;
  const orderBy = useRef(_orderBy).current;

  useEffect(() => {
    let ref = collection(projectFirestore, collectionName);

    let q;
    if (query && orderBy) {
      q = firestoreQuery(ref, where(...query), firestoreOrderBy(...orderBy));
    } else if (query) {
      q = firestoreQuery(ref, where(...query));
    } else if (orderBy) {
      q = firestoreQuery(ref, firestoreOrderBy(...orderBy));
    } else {
      q = ref;
    }

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setDocuments(results);
        setError(null);
      },
      (error) => {
        console.log(error);
        setError("could not fetch the data");
      }
    );

    return () => unsubscribe();
  }, [collectionName, query, orderBy]);

  return { documents, error };
};
