import React, { useState } from 'react';
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { projectFirestore } from '../../firebase/config';
import Papa from 'papaparse';

const CsvUpload = () => {
    const [timerFile, setTimerFile] = useState(null);
    const [scannerFile, setScannerFile] = useState(null);

    const handleFileChange = (e, setFile) => {
        setFile(e.target.files[0]);
    };

    const parseCsv = (file) => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                complete: (results) => {
                    // console.log(`Parsed CSV data from ${file.name}:`, results.data);
                    resolve(results.data);
                },
                header: false,
                skipEmptyLines: true,
            });
        });
    };

    const processFiles = async (timerFile, scannerFile, caperunType) => {
        const [timerResults, scannerResults] = await Promise.all([parseCsv(timerFile), parseCsv(scannerFile)]);
        // console.log('Timer Results:', timerResults);
        // console.log('Scanner Results:', scannerResults);

        const positionToTime = {};
        timerResults.forEach((row) => {
            if (row.length >= 3 && !isNaN(row[0])) {
                const [position, , time] = row;
                positionToTime[`P${position.trim().padStart(4, '0')}`] = time.trim();
            }
        });
        // console.log('Position to Time Mapping:', positionToTime);

        const uniqueIdToTime = {};
        scannerResults.forEach((row) => {
            if (row.length >= 3 && row[0] !== 'Start of File') {
                const [uniqueId, position] = row;
                const time = positionToTime[position.trim()];
                if (time) {
                    uniqueIdToTime[uniqueId.trim()] = time;
                }
            }
        });
        // console.log('UniqueId to Time Mapping:', uniqueIdToTime);

        for (const uniqueId in uniqueIdToTime) {
            const time = uniqueIdToTime[uniqueId];
            // console.log(`Processing uniqueId ${uniqueId} with time ${time}`);
            const guestQuery = query(collection(projectFirestore, 'guests'), where('uniqueId', '==', uniqueId));
            const guestQuerySnapshot = await getDocs(guestQuery);
            if (!guestQuerySnapshot.empty) {
                guestQuerySnapshot.forEach(async (doc) => {
                    // console.log(`Updating document ${doc.id} with time ${time} and caperun ${caperunType}`);
                    await updateDoc(doc.ref, { time: time, caperun: caperunType });
                });
            } else {
                // console.log(`No document found for uniqueId ${uniqueId}`);
            }
        }
    };

    const handleSubmit = async (e, caperunType) => {
        e.preventDefault();

        if (!timerFile || !scannerFile) {
            alert('Please upload both files');
            return;
        }

        try {
            await processFiles(timerFile, scannerFile, caperunType);
            alert('Data uploaded successfully');
        } catch (error) {
            console.error('Error uploading data:', error);
            alert('Error uploading data');
        }
    };

    const handleDeleteRunnerTimes = async () => {
        try {
            const runnerQuery = query(collection(projectFirestore, 'guests'), where('caperun', '==', 'runner'));
            const runnerQuerySnapshot = await getDocs(runnerQuery);

            if (!runnerQuerySnapshot.empty) {
                runnerQuerySnapshot.forEach(async (doc) => {
                    // console.log(`Deleting time for document ${doc.id}`);
                    await updateDoc(doc.ref, { time: '' });
                });
                alert('Times deleted successfully for all runners');
            } else {
                alert('No runners found');
            }
        } catch (error) {
            // console.error('Error deleting times:', error);
            alert('Error deleting times');
        }
    };

    const handleDeleteWalkerTimes = async () => {
        try {
            const runnerQuery = query(collection(projectFirestore, 'guests'), where('caperun', '==', 'walker'));
            const runnerQuerySnapshot = await getDocs(runnerQuery);

            if (!runnerQuerySnapshot.empty) {
                runnerQuerySnapshot.forEach(async (doc) => {
                    // console.log(`Deleting time for document ${doc.id}`);
                    await updateDoc(doc.ref, { time: '' });
                });
                alert('Times deleted successfully for all walkers');
            } else {
                alert('No walkers found');
            }
        } catch (error) {
            console.error('Error deleting times:', error);
            alert('Error deleting times');
        }
    };

    const handleDeleteVolunteerTimes = async () => {
        try {
            const runnerQuery = query(collection(projectFirestore, 'guests'), where('caperun', '==', 'volunteer'));
            const runnerQuerySnapshot = await getDocs(runnerQuery);

            if (!runnerQuerySnapshot.empty) {
                runnerQuerySnapshot.forEach(async (doc) => {
                    // console.log(`Deleting time for document ${doc.id}`);
                    await updateDoc(doc.ref, { caperun: ''});
                });
                alert('Times deleted successfully for all volunteers');
            } else {
                alert('No volunteers found');
            }
        } catch (error) {
            // console.error('Error deleting times:', error);
            alert('Error deleting times');
        }
    };

    return (
        <div>
            <h1>Upload CSV Files</h1>
            <form>
                <div>
                    <label>
                        Timer Results:
                        <input type="file" accept=".csv" onChange={(e) => handleFileChange(e, setTimerFile)} />
                    </label>
                </div>
                <div>
                    <label>
                        Scanner Results:
                        <input type="file" accept=".csv" onChange={(e) => handleFileChange(e, setScannerFile)} />
                    </label>
                </div>
                <button type="submit" onClick={(e) => handleSubmit(e, 'runner')}>Upload (Runners)</button>
                <button type="submit" onClick={(e) => handleSubmit(e, 'walker')}>Upload (Walkers)</button>
                <button type="submit" onClick={(e) => handleSubmit(e, 'volunteer')}>Upload (Volunteers)</button>
            </form>
            <button onClick={handleDeleteRunnerTimes}>Delete Runner Times</button>
            <button onClick={handleDeleteWalkerTimes}>Delete Walker Times</button>
            <button onClick={handleDeleteVolunteerTimes}>Delete Volunteer Names</button>
        </div>
    );
};

export default CsvUpload;
