import React, { useState, useEffect } from 'react';
import { useFirestore } from '../../hooks/useFirestore';
import Signup from '../signup/Signup';
import Addguest from '../addguest/Addguest';

export default function Admin() {
    const { fetchAllDocuments, response } = useFirestore('guests');
    const [allGuests, setAllGuests] = useState([]);

    const findAllFields = (data) => {
        const allFields = new Set();
        const fieldsToExclude = ['createdAt', 'uid', 'id']; // List of fields to exclude

        data.forEach(item => {
            Object.keys(item).forEach(key => {
                if (!fieldsToExclude.includes(key)) {
                    allFields.add(key);
                }
            });
        });

        return Array.from(allFields);
    }

    const sortBySurname = (data) => {
        return data.sort((a, b) => {
            if (a.surname < b.surname) return -1;
            if (a.surname > b.surname) return 1;
            return 0;
        });
    };

    const groupByUID = (sortedData) => {
        return sortedData.reduce((group, item) => {
            const { uid } = item;
            group[uid] = group[uid] ?? [];
            group[uid].push(item);
            return group;
        }, {});
    };

    const flattenGroupedData = (groupedData) => {
        return Object.values(groupedData).flat();
    };

    const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        // Find all unique fields
        const headers = findAllFields(array);
        str += headers.join(',') + '\r\n';



        // Convert each object to CSV row
        for (let i = 0; i < array.length; i++) {
            let line = headers.map(header => {
                return array[i][header] ? `"${array[i][header]}"` : '';
            }).join(',');

            str += line + '\r\n';
        }

        return str;
    }

    const exportToCSV = () => {
        const sortedData = sortBySurname(allGuests);
        const groupedData = groupByUID(sortedData);
        const dataForExport = flattenGroupedData(groupedData);

        const csvData = convertToCSV(dataForExport);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');

        if (link.download !== undefined) { // feature detection for download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'guests.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }



    useEffect(() => {
        fetchAllDocuments();
    }, []);

    useEffect(() => {
        if (response.success) {
            setAllGuests(response.document);
        }
    }, [response]);

    // Check if the data is still loading or if there's an error
    if (response.isPending) return <div>Loading...</div>;
    if (response.error) return <div>Error: {response.error}</div>;

    return (
        <div>
            <h1>Admin Page</h1>
            <button onClick={exportToCSV}>Export as CSV</button>
            <Signup />
            <Addguest /> 
            {/* <ul>
                {allGuests.map((guest) => (
                    <li key={guest.id}>
                        {guest.firstname} {guest.lastname}
                        ,{guest.rsvp}
                        ,{guest.starter}
                        ,{guest.main}
                        ,{guest.fussyEater && <span>true</span>}
                        ,{guest.foodPreference}
                        ,{guest.caperun}
                        ,{guest.bus2lm}
                        ,{guest.bus2col}
                    </li>
                ))}
            </ul> */}
        </div>
    );
}
