import React, { useState } from 'react';
import styles from './Caperun.module.css'; // Import the CSS module
import FaqItem from '../../components/FaqItem'; // Adjust the path if necessary
import UnhappyNug from '../../assets/unhappynug.jpeg'
import { useAuthContext } from '../../hooks/useAuthContext';
import { useCollection } from '../../hooks/useCollection';
import CaperunResults from './CaperunResults';

export default function Caperun() {

  const { user } = useAuthContext();
  const { documents, error } = useCollection(
    'guests',
    ['uid', '==', user.uid],
    ['createdAt', 'desc']
  );

  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "How far is the meeting point to the hotels?",
      answer: "If you are staying at the recommended hotels (The George or St Nicholas), the park is just 5 minutes walk away."
    },
  ];

  const toggleFAQ = index => {
    setActiveIndex(index === activeIndex ? null : index);
  };


  return (
    <div className={styles.container}>

      <div className={styles.topContent}>
        <div className={styles.topText}>
          <h1>CapeRun</h1>

          <p>We can’t think of a better way to kick off our wedding day than with a 5k run with our nearest & dearest! So dust off those sneakers, stretch those hamstrings, and get ready for a morning of fun, sweat & endorphins!</p>
          <p><span className={styles.dateHeading}>Date: </span>Friday, 19th July 2024</p>
          <p><span className={styles.dateHeading}>Meeting time:</span> 8am (for a prompt 8.15am start)</p>
          <p><span className={styles.dateHeading}>Meeting point:</span> By the bandstand behind the castle at Colchester Castle Park (see the map below)</p>
          <h3>Your Choices:</h3>
          <ul className={styles.bulletPointList}>
            <li><span className={styles.dateHeading}>2.5k Walk:</span> A leisurely stroll for those who prefer to savour the moment and take in the beauty of Colchester Castle Park.
            </li><li><span className={styles.dateHeading}>5k Run/Jog:</span> For those who want to break a sweat, and earn their wedding cake!
            </li>
            <li><span className={styles.dateHeading}>Not into Running or Walking?</span> No Problem - Volunteer!
            </li></ul>
          <p>Be a part of the Cape Run fun as a marshal.
          </p>
        </div>
        <div className={styles.topImage}>
          <img src={UnhappyNug} alt="Cara sad at being made to Parkrun in the rain" />
          <div className={styles.imageDescription}>
            Cara is excited!
          </div>
        </div>
      </div>

      {error && <p>{error}</p>}
      {documents && (
        <CaperunResults></CaperunResults>
      )}


      <iframe
        title="parkrunmap"
        src="https://www.google.com/maps/d/embed?mid=1HoT5UvqQ-_tJEBlHOnsekrdjtkQ&ehbc=2E312F"
        width="100%"
        height="480"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        frameBorder="0"
      ></iframe>

      <div className={styles.faqContainer}>
        {faqs.map((faq, index) => (
          <FaqItem
            key={index}
            faq={faq}
            isActive={index === activeIndex}
            onClick={() => toggleFAQ(index)}
          />
        ))}
      </div>
    </div >
  );
}
