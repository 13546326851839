import styles from './Navbar.module.css'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
import CapeAir from '../assets/CapeAir.svg'

export default function Navbar() {
  const { logout } = useLogout()
  const { user } = useAuthContext()
  const location = useLocation()
  const [currentPath, setCurrentPath] = useState('')

  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [location.pathname])

  return (
    <div>

      <nav className={styles.navbar}>
        <ul>
          <li className={styles.title}><Link to="/" ><img className={styles.img} src={CapeAir} alt="CapeAir" /></Link></li>
          <li className={styles.subtitle}>The world's Capeiest Airline
          </li>
        </ul>
      </nav>

      <nav className={styles.navbar}>
        <div className={styles.numbertwo}>
          <div className={styles.hidden}>

            <ul>
              {!user && (
                <>
                  {/* <li className={currentPath === '/' ? styles.activeNav : ''}><Link to="/">Home</Link></li> */}
                  <li className={currentPath === '/login' ? styles.activeNav : ''}><Link to="/login">Manage My Booking</Link></li>
                  {/* <li><Link to="#">Information</Link></li>
                  <li><Link to="#">Book</Link></li> */}
                </>
              )}

              {user && (
                <>
                  <li className={currentPath === '/Destination' ? styles.activeNav : ''}><Link to="/Destination">Destination</Link></li>
                  <li className={currentPath === '/Flightplan' ? styles.activeNav : ''}><Link to="/Flightplan">Flight Plan</Link></li>
                  <li className={currentPath === '/Manage' ? styles.activeNav : ''}><Link to="/Manage">Check-in</Link></li>
                  <li className={currentPath === '/Accommodation' ? styles.activeNav : ''}><Link to="/Accommodation">Accom modation</Link></li>
                  <li className={currentPath === '/GroundTransportation' ? styles.activeNav : ''}><Link to="/GroundTransportation">Ground Transportation</Link></li>
                  <li className={currentPath === '/CapeRun' ? styles.activeNav : ''}><Link to="/CapeRun">CapeRun</Link></li>
                  <li className={currentPath === '/Faq' ? styles.activeNav : ''}><Link to="/Faq">FAQs</Link></li>
                  <li className={currentPath === '/' ? styles.activeNav : ''}><Link onClick={logout}>Logout</Link></li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}
