import React, { useState, useEffect } from 'react';
import { useFirestore } from '../../hooks/useFirestore';
import { ReactComponent as EditIcon } from '../../assets/edit_icon.svg';
import styles from './Manage.module.css';
import Select from 'react-select'
import Creatable from 'react-select/creatable';
import CreatableSelect from 'react-select/creatable';


export default function GuestList({ guests }) {
  const initialSelectedRsvps = guests.reduce((acc, guest) => {
    acc[guest.id] = guest.rsvp || ''; // Use the RSVP value from the guest or set it to an empty string if undefined
    return acc;
  }, {});

  const initialFussyEater = guests.reduce((acc, guest) => {
    acc[guest.id] = guest.fussyEater || '';
    return acc;
  }, {});

  const initialFoodPreference = guests.reduce((acc, guest) => {
    acc[guest.id] = guest.foodPreference || '';
    return acc;
  }, {});



  const { deleteDocument, updateDocument } = useFirestore('guests');
  const [selectedRsvps, setSelectedRsvps] = useState(initialSelectedRsvps);
  const [selectedStarters, setSelectedStarters] = useState({});
  const [selectedMains, setSelectedMains] = useState({});
  const [fussyEater, setFussyEater] = useState(initialFussyEater);
  const [foodPreference, setFoodPreference] = useState(initialFoodPreference);
  const [errorMessages, setErrorMessages] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [showOptions, setShowOptions] = useState({});
  const [showFoodPreference, setShowFoodPreference] = useState(initialFussyEater);
  const [generalErrorMessage, setGeneralErrorMessage] = useState('');


  const handleBoxClick = (id) => {
    setShowOptions((prevShowOptions) => ({
      ...prevShowOptions,
      [id]: !prevShowOptions[id],
    }));
  };

  const handleFussyChange = (guestId) => {
    setFussyEater((prevFussyEater) => ({
      ...prevFussyEater,
      [guestId]: !prevFussyEater[guestId],
    }));
    setShowFoodPreference((prevShowFoodPreference) => ({
      ...prevShowFoodPreference,
      [guestId]: !prevShowFoodPreference[guestId],
    }));
  };

  const handleCloseAllBoxes = () => {
    const updatedShowOptions = {};

    // Set all showOptions to false
    Object.keys(showOptions).forEach((key) => {
      updatedShowOptions[key] = false;
    });

    setShowOptions(updatedShowOptions);
  };

  const handleDropdownChange = (guestId, field, eventOrOption) => {
    let newValue;

    if (field === 'rsvp' || field === 'fussyeater' || field === 'foodpreference') {
      newValue = eventOrOption.target.value;
    } else {
      newValue = eventOrOption ? eventOrOption.value : '';
    }

    if (field === 'rsvp') {
      setSelectedRsvps(prev => ({ ...prev, [guestId]: newValue }));
    } else if (field === 'starter') {
      setSelectedStarters(prev => ({ ...prev, [guestId]: newValue }));
    } else if (field === 'main') {
      setSelectedMains(prev => ({ ...prev, [guestId]: newValue }));
    } else if (field === 'foodpreference') {
      // Update the foodPreference state for the specific guest
      setFoodPreference(prev => ({ ...prev, [guestId]: newValue }));
    } else if (field === 'fussyeater') {
      setFussyEater(prev => ({ ...prev, [guestId]: newValue }));
    }
  };

  useEffect(() => {
    const allRsvpsPopulated = guests.every((guest) =>
      selectedRsvps[guest.id] !== undefined || guest.rsvp !== undefined
    );

    setSaveDisabled(!allRsvpsPopulated);
  }, [selectedRsvps, guests]);

  const handleSave = () => {
    const updates = [];
    const rsvpErrors = {};
    const starterErrors = {};
    const mainErrors = {};
    const fussyEaterErrors = {};

    guests.forEach((guest) => {
      const guestId = guest.id;
      const newRsvp = selectedRsvps[guestId] || guest.rsvp || '';
      const newStarter = selectedStarters[guestId] || guest.starter || '';
      const newMain = selectedMains[guestId] || guest.main || '';
      const newFussyEater = !!fussyEater[guestId] || guest.fussyeater || false;
      const newFoodPreference = foodPreference[guestId] || guest.foodPreference || '';

      if (newRsvp !== 'true' && newRsvp !== 'false') {
        rsvpErrors[guestId] = 'Please select "Yes" or "No" for RSVP.';
      }

      if (newRsvp === 'true' && (newMain === '')) {
        mainErrors[guestId] = 'Please select a valid main option.';
      }

      if (newRsvp === 'true' && (newStarter === '')) {
        starterErrors[guestId] = 'Please select a valid starter option.';
      }
      if (Object.keys(rsvpErrors).length > 0 || Object.keys(mainErrors).length > 0 || Object.keys(starterErrors).length > 0) {
        const errorGuests = guests.filter(guest => rsvpErrors[guest.id] || mainErrors[guest.id] || starterErrors[guest.id])
          .map(guest => `${guest.firstname} ${guest.lastname}`)
          .join(', ');
        setGeneralErrorMessage(`Please check errors for the following guests: ${errorGuests}`);
        setErrorMessages({ ...rsvpErrors, ...mainErrors, ...starterErrors });
        setSuccessMessage('');
      } else {
        setGeneralErrorMessage('');
      }

      updates.push({
        id: guestId,
        rsvp: newRsvp,
        starter: newStarter,
        main: newMain,
        fussyEater: newFussyEater,
        foodPreference: newFoodPreference,
      });
    });

    if (Object.keys(rsvpErrors).length > 0 || Object.keys(mainErrors).length > 0 || Object.keys(starterErrors).length > 0) {
      setErrorMessages({ ...rsvpErrors, ...mainErrors, ...starterErrors });
      setSuccessMessage('');
    } else {
      setErrorMessages({});

      Promise.all(updates.map((update) => updateDocument(update.id, update)))
        .then(() => {
          handleCloseAllBoxes();
          setSuccessMessage('Choices Saved Successfully');
          setTimeout(() => {
            setSuccessMessage('');
          }, 5000);
        })
        .catch((error) => {
          console.error('Error saving choices:', error);
        });
    }
  };

  const starterOptions = [
    { value: 'Prawns', label: 'Pan Fried Citrus and Pepper Prawns' },
    { value: 'Chicken', label: 'Sesame Chicken' },
    { value: 'Veggie', label: "Glazed Goat's Cheese (v)" },
  ]

  const mainOptions = [

    { value: 'Lamb', label: 'Slow Roasted Shoulder of Lamb' },
    { value: 'Chicken', label: 'Thyme Roasted Breast of Chicken, Crispy Pancetta' },
    { value: 'Veggie', label: 'Mushroom Kiev (v)' },
  ]


  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#FFF;" : "#000;",
      backgroundColor: state.isSelected ? "#D5AF34;" : "#FFF;",
      color: state.isFocused ? "#FFF;" : "#000;",
      backgroundColor: state.isFocused ? "#D5AF34;" : "#FFF;",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#FFF;",

    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000;" }),
  };


  return (
    <div>
      <ul className={styles.transactions}>
        <h1>Invited Guests</h1>
        <button className={styles.save} onClick={handleSave} disabled={saveDisabled}>
          Save
        </button>
        {generalErrorMessage && (
          <p className={styles.error}>{generalErrorMessage}</p>
        )}
        {
          successMessage && (
            <p className={styles.success}>{successMessage}</p>
          )
        }
        {guests.map((guest) => (
          <li key={guest.id}>
            <div className={styles.name}>
              <p className={styles.lastname}>{guest.firstname}</p>
              <p>{guest.lastname}&nbsp;</p>
            </div>

            {!showOptions[guest.id] && (
              <>
                <button className={styles.edit} onClick={() => handleBoxClick(guest.id)}>
                  <EditIcon />
                </button>
                <div className={styles.rsvp}>
                  <div className={styles.details}>
                    <p>RSVP: {guest.rsvp === 'true' ? 'Yes!' : 'No'}</p>
                    {guest.rsvp === 'true' && (
                      <>
                        <p>Starter: {guest.starter}</p>
                        <p>Main: {guest.main}</p>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            {showOptions[guest.id] && (
              <div className={styles.options}>
                <div className={styles.row}>
                  <p className={styles.label}>RSVP:</p>
                  <div className={styles.optionsRow}>

                    <input
                      type="radio"
                      id={`rsvp_yes_${guest.id}`}
                      name={`rsvp_${guest.id}`}
                      value="true"
                      className={styles.hiddenRadio}
                      checked={selectedRsvps[guest.id] === 'true'}
                      onChange={(e) => handleDropdownChange(guest.id, 'rsvp', e)}
                    />
                    <label htmlFor={`rsvp_yes_${guest.id}`} className={styles.selectableBox}>
                      Yes
                    </label>

                    <input
                      type="radio"
                      id={`rsvp_no_${guest.id}`}
                      name={`rsvp_${guest.id}`}
                      value="false"
                      className={styles.hiddenRadio}
                      checked={selectedRsvps[guest.id] === 'false'}
                      onChange={(e) => handleDropdownChange(guest.id, 'rsvp', e)}
                    />
                    <label htmlFor={`rsvp_no_${guest.id}`} className={styles.selectableBox}>
                      No
                    </label>
                  </div>
                </div>

                <div>
                  <label htmlFor={`starter_${guest.id}`} className={styles.label}>
                    Starter:
                  </label>
                  <Select
                    styles={customStyles}
                    name="starter"
                    id={`starter_${guest.id}`}
                    onChange={(option) => handleDropdownChange(guest.id, 'starter', option)}
                    value={starterOptions.find(option => option.value === (selectedStarters[guest.id] || guest.starter))}
                    isDisabled={selectedRsvps[guest.id] !== 'true'}
                    options={starterOptions}
                  />

                </div>

                <div>
                  <label htmlFor={`main_${guest.id}`} className={styles.label}>
                    Main:
                  </label>
                  <Select
                    styles={customStyles}
                    name="main"
                    id={`main_${guest.id}`}
                    onChange={(option) => handleDropdownChange(guest.id, 'main', option)}
                    value={mainOptions.find(option => option.value === (selectedMains[guest.id] || guest.main))}
                    isDisabled={selectedRsvps[guest.id] !== 'true'}
                    options={mainOptions}
                  />
                </div>

                <div>
                  <input
                    className={styles.checkBox}
                    type="checkbox"
                    id={`fussyeater_${guest.id}`}
                    name="fussyEater"
                    checked={fussyEater[guest.id] || guest.fussyeater || false}
                    disabled={selectedRsvps[guest.id] !== 'true'}
                    onChange={(e) => handleFussyChange(guest.id, 'fussyeater', e)}
                  />
                  <label htmlFor="fussyEater">Fussy Eater?</label>
                </div>

                {showFoodPreference[guest.id] && (
                  <div>
                    <textarea
                      className={styles.textArea}
                      type="text"
                      id={`foodpreference_${guest.id}`}
                      name="foodPreference"
                      value={foodPreference[guest.id] || guest.foodpreference || ''}
                      onChange={(e) => handleDropdownChange(guest.id, 'foodpreference', e)}
                    />
                  </div>
                )}
              </div>
            )}

            <div>
              {/* Delete button */}
              {/* <button className={styles.delete} onClick={() => deleteDocument(guest.id)}>x</button> */}
            </div>

            {errorMessages[guest.id] && (
              <p className={styles.error}>{errorMessages[guest.id]}</p>
            )}
          </li>
        ))}
        <button className={styles.save} onClick={handleSave} disabled={saveDisabled}>
          Save
        </button>
        {generalErrorMessage && (
          <p className={styles.error}>{generalErrorMessage}</p>
        )}
        {
          successMessage && (
            <p className={styles.success}>{successMessage}</p>
          )
        }
      </ul>

    </div >
  );
}
