import { useState } from 'react';
import React from 'react';
import { useLogin } from '../../hooks/useLogin';

//styles
import styles from './Login.module.css';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login, isPending, error } = useLogin(); // Assuming useLogin hook manages authentication and error state

    const handleBookingRefChange = (e) => {
        // Convert the input value to uppercase
        const uppercaseRef = e.target.value.toUpperCase();
        setPassword(uppercaseRef);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const sanitizedSurname = email.replace(/\s/g, '');
        login(sanitizedSurname + '@' + password + '.com', password)
            .catch((loginError) => {
                // Handle the error here if login function is promise-based
                // This depends on the implementation of useLogin
                console.error('Login failed:', loginError);
            });
    };

    return (
        <form onSubmit={handleSubmit} className={styles['login-form']}>
            <h2>Find your reservation</h2>
            <label>
                <span>Surname:</span>
                <input
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
            </label>
            <label>
                <span>Booking Ref:</span>
                <input
                    type="text"
                    onChange={handleBookingRefChange}
                    value={password}
                />
            </label>
            {!isPending && <button className="btn">Login</button>}
            {isPending && <button className="btn" disabled>loading</button>}
            {error && <p className={styles.error}>Mismatched Surname and or booking reference, try again or contact Cara or Capewell for assistance.</p>} {}
        </form>
    );
}
