import React, { useState } from 'react'
import styles from './Destination.module.css'
import FaqItem from '../../components/FaqItem'; // Adjust the path if necessary
import CountdownTimer from '../../components/CountdownTimer';
import lmt from '../../assets/lmt.jpg'
import { Link } from 'react-router-dom';

const Destination = () => {


  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "",
      answer: ""
    },
  ];

  const toggleFAQ = index => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className={styles.container}>
      <h1>Destination</h1>

      <p className={styles.topInfo}><span className={styles.dateHeading}>Destination:</span> Layer Marney Towers, Colchester</p>

      <p className={styles.topInfo}><span className={styles.dateHeading}>Date: </span>19th July 2024</p>

      <div className={styles.mainImg}>
        <img src={lmt} alt="Picture of Layer Marney Tower" />
        <div className={styles.countdownOverlay}>

          <p><CountdownTimer endDate="2024-07-19T13:15:00" /> </p>
        </div>
      </div>
      <h2>Next Steps</h2>
      <p>Prepare for your flight:</p>
      <ul className={styles.bulletPointList}>
        <li><Link to="/Manage">Check-in</Link> and select meal choices for all passengers</li>
        <li>Check out the <Link to="/Accommodation">accommodation</Link> page and make sure you have somewhere to stay</li>
        <li>Ensure you have your transportation for the day sorted through the <Link to="/GroundTransportation">Ground Transportation</Link> page</li>
        <li>Sign-up for <Link to="/Caperun">CapeRun</Link></li>
        <li>Check-out the <Link to="/Faq">FAQs</Link> for more information</li>
      </ul>

      <h2>Layer Marney Towers</h2>

      <p>Get ready to take flight into the past at Layer Marney Towers, and party like the Tudors.
        We'll be sipping fizz, and munching on canapés in the same spots where Henry VIII and Elizabeth I once strutted their stuff (most likely!).</p>

      <p>Built in the early 16th century, Lord Marney (Henry VIII's right hand man) decided to throw some serious architectural flair into the mix and built Layer Marney Tower. This majestic tower stands tall and proud, reflecting Lord Marney's swag and his tight bond with the King.</p>

      <p>And did you know? Colchester boasts the prestigious title of being the oldest town in England. From ancient Roman ruins to captivating medieval architecture, there's a treasure trove of heritage to explore during your stay.</p>

      <p>So fasten your seatbelts and prepare for a wedding celebration where the past meets the present.</p>






    </div>
  );
}
export default Destination 