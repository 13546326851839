import React from 'react';
import styles from '../pages/faq/Faq.module.css'; // Adjust the path if necessary


const FaqItem = ({ faq, isActive, onClick }) => {
    return (
        <div className={styles.faqItem}>
            <div className={styles.faqQuestion} onClick={onClick}>
                {faq.question}
            </div>
            {isActive && (
                <div className={`${styles.faqAnswer} ${isActive ? styles.active : ''}`}>
                    {faq.answer}
                </div>
            )}
        </div>
    );
};



// return (
//     <div className={styles.faqItem}>
//         <div className={styles.faqQuestion} onClick={onClick}>
//             {faq.question}
//         </div>
//         <div className={`${styles.faqAnswer} ${isActive ? styles.active : ''}`}>
//             {faq.answer.split('\n').map((line, index) => (
//                 <span key={index}>
//                     {parseText(line)}
//                     <br />
//                 </span>
//             ))}
//         </div>
//     </div>
// );
// };

export default FaqItem;