import React, { useState } from 'react'
import styles from './Accommodation.module.css'
import FaqItem from '../../components/FaqItem'; // Adjust the path if necessary
import stnick from '../../assets/stnick.jpg'
import stnickroom from '../../assets/stnickroom.jpg'
import george from '../../assets/george.jpg'

const Accomodation = () => {


  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "How far is the meeting point to the hotels?",
      answer: "If you are staying at the recommended hotels (The George or St Nicholas), the park is just 2 minutes walk away."
    },
  ];

  const toggleFAQ = index => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className={styles.container}>

      <div className={styles.topContent}>
        <div className={styles.topText}>
          <h1>Accommodation</h1>

          <p>We've secured block bookings at two Colchester hotels (The George & St Nicholas), offering a range of room sizes. Our buses will conveniently drop you off at these hotels at the end of the night. However, feel free to choose alternative accommodations if you prefer.</p>
          <p>We have secured preferential rates across a variety of room sizes at both hotels.</p>
          <p>Both hotels are part of the same chain and are opposite each other. Check-in, breakfast & other facilities are at the same place (St. George).</p>


          <h2>How to secure a room</h2>
          <p>To reserve a room, please email the below address let them know you’d like to reserve the room under our block booking. Remember to specify the below:</p>
          <ul className={styles.bulletPointList}>
            <li><span className={styles.dateHeading}>Quote the reference:</span>GA000169</li>
            <li>Preferred hotel (otherwise they will quote you for rooms at The George only)</li>
            <li>Nights you’ll be staying</li>
            <li>Preferred room type</li>
          </ul>

          <h2>Contact Information</h2>
          <p>Both The George & St Nicholas can be contacted through the same contact details:</p>
          <ul className={styles.bulletPointList}>

          <li>01206 578494</li>
          <li>
          <a
            href="mailto:receptionmanager@thegeorgehotelcolchester.co.uk"
            target="_blank" rel="noopener noreferrer"
            className={styles.emailLink}>
            receptionmanager@thegeorgehotelcolchester.co.uk
          </a>
          </li>
          </ul>
          <h2>Find out more through their websites</h2>
          <p><a href="https://www.suryahotels.co.uk/stnicholashotel/" target="_blank" rel="noopener noreferrer">St Nicholas</a> (££)</p>
        </div>
      </div>
      <div className={styles.mainImg}>
        <img src={stnick} alt="Entrance of the St Nicholas Hotel" />
      </div>
      <div className={styles.mainImg}>
        <img src={stnickroom} alt="Room at the St Nicholas Hotel" />
      </div>
      <p><a href="https://www.suryahotels.co.uk/thegeorgehotel/" target="_blank" rel="noopener noreferrer">George</a> (£££)</p>
      <div className={styles.mainImg}>
        <img src={george} alt="Room at the George Hotel" />
      </div>

      <h2>Booking Deadline</h2>
      <p>We recommend booking as soon as possible to maximise your chances of securing the room type you want. Please contact the hotel directly to confirm the cancellation policy. </p>
      <p>All unreserved rooms will be released by <span className={styles.dateHeading}>15th May 2024</span>. To benefit from the preferential rates and guarantee a room please book before this date.</p>

      <h2>Rooms Available</h2>
      <p>Prices quoted below are per night and includes breakfast</p>

      <table className={styles.table} >
        <tr>
          <th>Room Type</th>
          <th>St Nicholas</th>
          <th>The George</th>
        </tr>
        <tr>
          <td>Double</td>
          <td>£76</td>
          <td>£116</td>
        </tr>
        <tr>
          <td>Twin</td>
          <td>£76</td>
          <td>£116</td>
        </tr>
        <tr>
          <td>Single</td>
          <td>-</td>
          <td>£90</td>
        </tr>
        <tr>
          <td>Family Room</td>
          <td>£134</td>
          <td>£145</td>
        </tr>
      </table>
      <p>*Family rooms are ideal for 3 people - with a king size bed & sofa bed</p>

      <h2>Check-in & check-out times</h2>
      <p>Check-in is from 14:00, and check-out is at 11:00</p>

      <h2>Breakfast</h2>
      <p>The rates include breakfast which will be served till 9.30am on Friday & till 10am on Saturday. However for the late risers, there are plenty of other breakfast/brunch options at both the hotel & on the high street if you miss the breakfast slot. Having said that, even Cara wakes up before noon for free brekkie</p>

      <h2>Location</h2>

      <p>The hotels are opposite each other and conveniently located to Colchester Castle park (2 mins walks away), where the Cape Run will take place Friday morning, and to the church (7 mins walk away) where the ceremony will take place. Situated on the bustling High Street, the hotels also offer easy access to numerous shops, supermarkets, restaurants and bars.</p>
   
      <iframe
       
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2462.413469226468!2d0.90120789999999!3d51.889918200000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d904f81573a893%3A0x1dc14dce86bc70c3!2sThe%20George%20Hotel%20-%20Surya%20Hotels!5e0!3m2!1sen!2suk!4v1705351794505!5m2!1sen!2suk" width="100%" height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        frameborder="0"
      ></iframe>

      {/* <div className={styles.faqContainer}>
        {faqs.map((faq, index) => (
          <FaqItem
            key={index}
            faq={faq}
            isActive={index === activeIndex}
            onClick={() => toggleFAQ(index)}
          />
        ))}
      </div> */}
    </div >
  )
}
export default Accomodation
