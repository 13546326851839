import React, { useState } from 'react';
import styles from './Faq.module.css'; // Import the CSS module
import FaqItem from '../../components/FaqItem'; // Adjust the path if necessary
import { Link } from 'react-router-dom';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "When is the wedding?",
      answer: () => (
        <span><p>19th July 2024
        </p></span>
      ),
    },
    {
      question: "What is the dress code?",
      answer: () => (
        <span><p>You'll be sad to hear it is not aviation chic!</p><p>For our celebration we recommend cocktail attire. This can be anything between formal to semi-formal wear.
        </p></span>
      ),
    },
    {
      question: "Is there a gift registry?",
      answer: () => (
        <span><p>Your presence at the wedding is of course the best gift. But if you would like to get us something, we'll leave it up to you to choose. Alternatively cash towards the honeymoon to fund Princess Cape's business class tickets are also welcome.
        </p></span>
      ),
    },
    {
      question: "Should we bring our kids?",
      answer: () => (
        <span><p>Our event is primarily tailored for adults & teens. However if you're faced with unavoidable circumstances and unable to organise suitable childcare please reach out to us directly.
        </p></span>
      ),
    },
    {
      question: "What is a 'CapeRun' and do we have to participate?",
      answer: () => (
        <span><p>Yes.</p><p>Please refer to the dedicated <Link to="/Caperun">CapeRun</Link> page on the website for further details
        </p></span>
      ),
    },
    {
      question: "When should we arrive?",
      answer: () => (
        <span><p>
          We recommend arriving the evening before (Thursday 18th July) so you're able to participate in the exciting run/walk the next day. There are lots of options for dinner in Colchester town centre, and we hope you can swing by to say hello before the big day.
        </p></span>
      ),
    },
    {
      question: "Where do you recommended we stay in Colchester?",
      answer: () => (
        <span><p>
          We've secured block bookings at two Colchester hotels (The George & St Nicholas), offering a range of room sizes. Our buses will conveniently drop you off at these hotels at the end of the night. However, feel free to choose alternative accommodations if you prefer. Refer to the <Link to="/Accommodation">Accommodation</Link> page for additional details.
        </p></span>
      ),
    },
    {
      question: "What time should we arrive at the church?",
      answer: () => (
        <span><p>To ensure everyone has ample time to settle in and enjoy the ceremony, we recommend arriving at 12:45pm. This allows for a smooth and timely start at 1pm, ensuring you don't miss any special moments.
        </p></span>
      ),
    },
    {
      question: "Will the ceremony and reception be indoors or outdoors?",
      answer: () => (
        <span><p>The main events, will be held indoors. However we're hoping to enjoy the British summer with the cocktail reception being held outdoors (weather permitting).
        </p></span>
      ),
    },
    {
      question: "How to get to Colchester from London by public transport?",
      answer: () => (
        <span><p>There are up to 4 direct trains every hour from London Liverpool Street to Colchester, with a journey time of an hour or less.
        </p></span>
      ),
    },
    {
      question: "I'm driving; is there parking?",
      answer: () => (
        <span><p>There is parking next to the recommended hotels which is a pay & display system, working on a first come, first served basis. </p><p>You can also drive to the reception at Layer Marney Towers if you wish which offers free overnight parking.
        </p></span>
      ),
    },
    {
      question: "What ground transportation is there?",
      answer: () => (
        <span><p>The ceremony will be held at St James the Less Catholic Church, centrally located in Colchester town centre, just a short stroll from the recommended hotels.</p><p>Post-ceremony, a dedicated bus will be provided to transport guests to the reception venue at Layer Marney Towers, a convenient 20-minute drive (8 miles) away.</p><p>At the end of the night the bus will return you to Colchester Town Centre, dropping you off at the recommended hotels (The George & St Nicholas). Scheduled return buses are at 11pm and 12:15 am. However if you need an earlier departure, it will of course be possible to call a taxi.
        </p></span>
      ),
    },
    // Add more FAQs here
  ];

  const toggleFAQ = index => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className={styles.faqContainer}>
      <h1>Frequently Asked Questions</h1>
      {faqs.map((faq, index) => (
        <FaqItem
          key={index}
          faq={{ ...faq, answer: faq.answer() }} // Call the function here
          isActive={index === activeIndex}
          onClick={() => toggleFAQ(index)}
        />
      ))}

    </div>
  );
};

export default Faq;
